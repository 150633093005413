﻿$(function (){
    $('[data-js="language"]').unbind('click').bind('click', (e) => {
        e.stopPropagation();
        let $this = $(e.target);
        // IE10 workaround
        if (!$this.hasClass('language')) {
            $this = $this.closest('.language');
        }
        $this.toggleClass('show');
        if ($this.hasClass('show')) {
            $('div:not([data-js="language"])').bind('click', (e) => {
                $('[data-js="language"]').trigger('click');
            });
            loadLanguageChooser();
        } else {
            $('div:not([data-js="language"])').unbind('click');
        }
    });
    $('[data-js="languageMobile"]').bind('click', (e) => {
        let $this = $(e.target);
        loadLanguageChooser(true);
    });
});
function loadLanguageChooser(mobile) {
    let url = window.url_GetLanguageChooser;
    $.ajax(url).done((data) => {
        if (mobile) {
            showLanguageChooserMobile(data);
        } else {
            showLanguageChooser(data);
        }
    });
}
function showLanguageChooser(data) {
    $('[data-js="languageSelectorContainer"]').html(data);
    $('.languageselector__link').unbind('click').bind('click', (e) => {
        startLoading();
    });
}
function showLanguageChooserMobile(data) {
    $('[data-js="mobilemenuSidebar"]').html(data).addClass('show');
}