export default class Dropdown {

    constructor() {

        //this.dropdowns = document.querySelectorAll('.hDropdown');
        this.doc = $(document);

    }

    init() {
       
        const scope = this;
        scope.dropdowns = $('.hDropdown').not('.select2-hidden-accessible').filter('SELECT');

        // Check if there are any dropdowns on the page
        if ($(scope.dropdowns)) {

            // Try to take options from the data-attributes of the select element
            $.each(scope.dropdowns, function() {

                let opt = {},
                    options = {};

                // Check if we need to display the search field
                if (typeof $(this).data("search") == 'undefined') {
                    opt.minimumResultsForSearch = 'Infinity'; // Setting the minimumResultsForSearch option to "Infinity" will hide the search field
                }

                // Check if the select should implement a custom/responsive width
                if (typeof $(this).data("width") != 'undefined') {
                    if ($(this).data("width") === "fill") {
                        opt.width = "100%";
                    } else {
                        opt.width = $(this).data("width");
                    }
                }

                if (typeof $(this).attr("multiple") != 'undefined') {
                    opt.width = "350px";

                    if ($(window).width() < 1600) {
                        opt.width = "180px";
                    }
                }

                // Check for a placeholder in data attributes
                if (typeof $(this).data('placeholder') != 'undefined') {
                    opt.placeholder = $(this).data('placeholder');
                }

                // Set options if needed
                if (typeof opt.minimumResultsForSearch != 'undefined') {

                    options.minimumResultsForSearch = opt.minimumResultsForSearch;

                }

                if (typeof opt.width != 'undefined') {

                    options.width = opt.width;

                }

                if(typeof $(this).data("tags") != 'undefined')
                {
                    options.tags =  $(this).data("tags");
                }

                if(typeof $(this).data("sorttype") != 'undefined')
                {
                    switch($(this).data("sorttype"))
                    {
                        case "None": break;
                        case "Ascending": {
                            options.sorter = function(data) {
                                return data.sort(function(a, b) {
                                    return a.text < b.text ? -1 : a.text > b.text ? 1 : 0;
                                });
                            }
                            break;
                        }
                        case "Descending": {
                            options.sorter = function(data) {
                                return data.sort(function(a, b) {
                                    return a.text > b.text ? -1 : a.text < b.text ? 1 : 0;
                                });
                            }
                            break;
                        }
                    }
                    
                }
               
                //Initiate placeholder after sorting to prevent it from being included in the sorting above
                if (typeof opt.placeholder != 'undefined') {
                    options.placeholder = opt.placeholder;
                }

                // Bind Select2 to the elements
                scope.bindings($(this), options);

            });

        }

        $('.btn--multiselect').unbind('click').bind('click', (e) => {
            let $this = $(e.target);
            let multiSelectIdentifier = $this.data('select');

            if ($this.data('btntype') === "clear") {
                $("." + multiSelectIdentifier).val(null).trigger("change");
            } else {
                $("." + multiSelectIdentifier + " option").prop("selected","selected");
                $("." + multiSelectIdentifier).trigger("change");
            }
        });
    }

    bindings(elem,options) {
        elem.select2(options);
    }

}


const dd = new Dropdown();


$(document).ready(function() {

    dd.init();

    window.initDropdowns = function(){     
        dd.init();
    }

});