﻿
window.setupTableBlur = function (table) {
    $(table).find('.swipeAfter').css({
        'visibility': 'visible',
        'height': $(table).height() - 18
    });

    $(table).find('.swipeBefore').css({
        'height': $(table).height() - 18
    });
    var wrapper = $(table).find('#resulttable__wrapper');
    wrapper.scroll(function (e) {
        setTableBlur(table, this);

    });
    setTableBlur(table, wrapper.get(0));
    
}

function setTableBlur(table, elm) {

    if (elm) {
        var scrollPercentage = 100 * elm.scrollLeft / (elm.scrollWidth - elm.clientWidth);

        if (elm.scrollLeft == 0 && elm.scrollWidth == elm.clientWidth) {
            $(table).find('.swipeAfter').css({
                'visibility': 'hidden',
                'opacity': '0'
            });

        } else {

            if (scrollPercentage > 90) {
                $(table).find('.swipeAfter').css({
                    'visibility': 'hidden',
                    'opacity': '0'
                });
            } else {
                $(table).find('.swipeAfter').css({
                    'visibility': 'visible',
                    'opacity': '1'
                });
            }

            if (scrollPercentage > 10) {
                $(table).find('.swipeBefore').css({
                    'visibility': 'visible',
                    'opacity': '1'
                });
            } else {
                $(table).find('.swipeBefore').css({
                    'visibility': 'hidden',
                    'opacity': '0'
                });
            }
        }
    }
}


