﻿$(function () {
    bindPlaceholderText();
    bindSelectPlaceholderText();
    bindClickOnLabel();

    $('[data-js="showMoreFilters"]').unbind('click').bind('click', (e) => {
        // Setting width due to select2 being inflexible
        let $this = $(e.target);
        let fieldset = $this.closest('fieldset');
        fieldset.find('.hidden').show();
        fieldset.find('.select2-container').width(fieldset.width());
        $this.hide();
        $(fieldset).find('[data-js="showLessFilters"]').show();
    });
    $('[data-js="showLessFilters"]').unbind('click').bind('click', (e) => {
        // Setting width due to select2 being inflexible
        let $this = $(e.target);
        let fieldset = $this.closest('fieldset');
        fieldset.find('.hidden').hide();
        fieldset.find('.select2-container').width(fieldset.width());
        $this.hide();
        $(fieldset).find('[data-js="showMoreFilters"]').show();
    });
    $('[data-js="mobileSearch"]').unbind('click').bind('click', (e) => {
        let $this = $(e.target);
        $this.toggleClass('active');
        $('[data-js="mobileSearchAreaContainer"], [data-js="mobileSearchProductsContainer"]').slideToggle();
    });
    $('[data-js="mobileSearchArea"]').unbind('click').bind('click', (e) => {
        let $this = $(e.target);
        let fieldset = $this.siblings('fieldset');
        $this.toggleClass('active');
        fieldset.slideToggle();
        fieldset.find('.select2-container').width('100%');
    });
    $('[data-js="mobileSearchProducts"]').unbind('click').bind('click', (e) => {
        let $this = $(e.target);
        $this.toggleClass('active');
        $this.next('div').slideToggle();
    });

    $('#carType_checkAll').on('click', (e) => {

        let checkboxes = $('#carType_checkAll').closest('.search-area__fieldset').find('input[type="checkbox"]:not(#carType_checkAll)');

        checkboxes.each(function () {
            $(this).prop('checked', !$(this).prop('checked'));

        });
        
    });

    $('#productGroup_checkAll').on('click', (e) => {

        let checkboxes = $('#productGroup_checkAll').closest('.search-area__fieldset').find('input[type="checkbox"]:not(#productGroup_checkAll)');

        checkboxes.each(function () {
            $(this).prop('checked', $('#productGroup_checkAll').prop('checked'));

        });

    });

    window.bindClickOnLabel = bindClickOnLabel;
    window.bindPlaceholderText = bindPlaceholderText;
    window.movePlaceholderText = movePlaceholderText;
});

function bindPlaceholderText() {
    $('[data-js="movableLabel"]').unbind('focusout').bind('focusout', (e) => {
        let $this = $(e.target);
        movePlaceholderText($this);
    });
    $('[data-js="movableLabel"]').trigger('focusout');
}

function bindSelectPlaceholderText() {

    $('[data-js="movableLabelSelect"]').on("select2:select", function (e) {
        let $this = $(e.target);
        $this.siblings('label').removeClass('label--inactive').addClass('label--active');
    });
}

function movePlaceholderText($this) {
    if ($this.val().length > 0) {
        $this.siblings('label').addClass('label--active');
    } else {
        if ($this.data('js') !== 'movableLabelSelect') {
            $this.siblings('label').removeClass('label--active');
        } else {
            $this.siblings('label').addClass('label--inactive');
        }
        
    }
}

function bindClickOnLabel() {
    $('.search-area__label').unbind('click').bind('click', (e) => {
        let $this = $(e.target);

        if (!$this.hasClass('label--active')) {
            $this.removeClass('label--inactive').addClass('label--active');
        }

        $this.prevAll('input').first().focus();
    });

}