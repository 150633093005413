﻿function bindDropDownTriggers() {
    let dropDownTriggers = $('[data-js="toggleDropdown"]');
    dropDownTriggers.unbind('click').bind('click', (e) => {
        let $this = $(e.target);
        $this.toggleClass('active');
        $this.parent().siblings('[data-js="dropdown"]').slideToggle();
    });
}
window.bindDropDownTriggers = bindDropDownTriggers;
$(function () {
    window.bindDropDownTriggers();
})