﻿window.isLoading = false;
window.blocktimerSpinner;
window.stopLoading = function (container) {
	clearTimeout(window.blocktimerSpinner);
    if (!container) {
        $('.loadContainer').remove();
    } else {
        $(container).find('.loadContainer').remove();
    }

    window.isLoading = false;

}

window.startLoading = function (container, top) {
	if (!window.isLoading) {
		window.isLoading = true;
		clearTimeout(window.blocktimerSpinner);
		window.blocktimerSpinner = setTimeout(function () {
			let fixed = false;
			if (typeof container === 'undefined') {
				container = $('body');
				fixed = true;
			}
			else {
				container = $(container);
			}

			if (container.length === 0) return;

			// Place spinner inline with no container and inverted
			if (container.data('spinner-type') === 'inline') {
				container.append('<div class="spinner spinner--inverted"></div>');
			}

			// Place spinner near the top
			else if (top) {
				container.append(`<div class="loadContainer loadContainer${fixed == true ? '--fixed' : ''} loadContainer--top"><div class="spinner"></div></div>`);
			}

			// Place spinner in middle of viewport
			else if (container.height() > $(window).height()) {
				container.append(`<div class="loadContainer loadContainer${fixed == true ? '--fixed' : ''} loadContainer--top"><div class="spinner spinner--fixed"></div></div>`);
			}

			// Place spinner in middle of container
			else {
				container.append(`<div class="loadContainer loadContainer${fixed == true ? '--fixed' : ''}"><div class="spinner"></div></div>`);
			}

			// Auto hide
			setTimeout(function () {
				stopLoading(container);
			}, 180000);
		}, 1500);
    }
}