﻿$(function() {
    $('[data-js="mobilemenuButton"]').unbind('click').bind('click', (e) => {
        let $this = $(e.target);
        if ($('[data-js="mobilemenuSidebar"]').hasClass('show')) {
            $('[data-js="mobilemenuSidebar"]').removeClass('show');
        } else {
            $this.toggleClass('mobilemenu__button--close');
            $('body').toggleClass('mobilemenu-active');
        }
    })
});