import {readCookie} from './readCookie';

export class AcceptCookie {

    constructor() {
        this.viewAcceptCookie = $('#js-accept-cookie');
        this.acceptCookieBtn = '#js-accept-cookie-btn';

        this.readCookie = new readCookie;

        //cookie options
        this.isInDOM = false;
        this.acceptCookieName = "acceptcookie";        
        this.state = "notCreated";
        this.template = "" +
            "<div class='container c-accept-cookie' id='js-accept-cookie'>" +
                "<div class='c-accept-cookie__inner'>" +
                    "<div class='c-accept-cookie__content'><span class='c-accept-cookie__text'>{text}</span> <a href='#' id='cookieModal'>{link}</a></div>" +
                    "<button type='button' class='c-accept-cookie__close' id='js-accept-cookie-btn' aria-label='close cookie'>" +
                        "<svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'>" +
                            "<g fill='#FFF' fill-rule='evenodd'><path d='M1.8931687.78712123L15.39248 14.2864325l-1.41421357 1.41421358L.47895513 2.20133478z'/>" +
                            "<path d='M15.39247965 2.20133433L1.89316837 15.7006456.4789548 14.28643206 13.9782661.78712075z'/></g>" +
                        "</svg>" +
                    "</button>" + 
                "</div>" +
            "</div>";
        this.elem = null;
    }

    init() {
        // Display at first (if not cookie set)
        //read cookie
        let readAcceptCookie = this.readCookie.getCookie(this.acceptCookieName);
    
        if (readAcceptCookie === "") {  
            this.show("visible");
        }

        $("#cookieModal").on('click', function(e) {
            var url = window.url_CookieInfo + "?viewOnly=true";
            addModalLarge("CookieInfoBox", url);
        });
    }

    show(state) {
        state = state || "visible";
        // Create element
        if (this.state === "notCreated") {

            this.state = "visible";

            // Create element
            this.elem = $(this.template);

            // Set texts
            this.setTexts();

            // Position (and place in DOM)
            this.setPosition();

            this.elem.find(this.acceptCookieBtn).on('click', () => { 
                let d = new Date();
                d.setTime(d.getTime() + (365*24*60*60*1000));
                let expires = "expires="+ d.toUTCString();

                document.cookie = this.acceptCookieName +"=" + $.now() +"; "+ expires +" ; path=/";
                this.elem.addClass("is-accepted");
            }); 
        }
    }

    setPosition () {
        this.elem.addClass("is-visible");
        // Place in DOM 
        if (!this.isInDOM) {
            this.isInDOM = true;
            this.elem.appendTo("body");            
        }
    }

    setTexts () {
        let html = this.elem.html();     
        html = html.replace("{text}", window.translation_cookieText);
        html = html.replace("{link}", window.translation_cookieLink);
        html = html.replace("{linkurl}", window.translation_cookieLinkUrl);
        html = html.replace("{btn}", window.translation_cookieBtn);
        this.elem.html(html);
    }
}

const cc = new AcceptCookie();

$(document).ready(function() {
    cc.init();
    window.initCookies = function(){
        cc.init();
    }
});